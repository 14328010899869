<template>
	<div>
		<v-row>
			<v-col cols="12">
				<profile-card />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-stepper class="elevation-0" v-model="e6" vertical>

					<v-stepper-step :complete="e6 > 1" step="1" @click="e6 = 1" :rules="step1">
						Uploading of Documents
					</v-stepper-step>

					<v-stepper-content step="1">
						<v-card class="mb-12" :elevation="0">
							<v-radio-group v-model="businessType" readonly row>
								<v-radio label="Single Proprietor" value="Single Proprietor"></v-radio>
								<v-radio label="Cooperative/Hybrid" value="Cooperative/Hybrid"></v-radio>
								<v-radio label="Corporation" value="Corporation"></v-radio>
							</v-radio-group>
							<sole-proprietor v-if="businessType === 'Single Proprietor'" :disabled="true" />
							<cooperative-hybrid v-if="businessType === 'Cooperative/Hybrid'" :disabled="true" />
							<corporation v-if="businessType === 'Corporation'" :disabled="true" />
						</v-card>
						<v-divider></v-divider>
						<v-row class="mt-2">
							<v-col>
								<v-btn color="primary" @click="$router.push({ path: '/sales-prospecting' })" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 2" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="2" :complete="e6 > 2" @click="e6 = 2" :rules="step2">
						Primary Business Address
					</v-stepper-step>
					<v-stepper-content step="2">
						<v-card :elevation="0">
							<v-form ref="step2" v-model="step2form">
								<primary-business-address :disabled="true" />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 3" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="3" :complete="e6 > 3" @click="e6 = 3" :rules="step3">
						Primary Contact Information
					</v-stepper-step>
					<v-stepper-content step="3">
						<v-card :elevation="0">
							<v-form ref="step3" v-model="step3form">
								<primary-contact-information :disabled="true" />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 4" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="4" :complete="e6 > 4" @click="e6 = 4" :rules="step4">
						Compliance Officer/Delegate
					</v-stepper-step>
					<v-stepper-content step="4">
						<v-card :elevation="0">
							<v-form ref="step4" v-model="step4form">
								<compliance-officer :disabled="true" />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 5" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="5" :complete="e6 > 5" @click="e6 = 5" :rules="step5">
						Business Information
					</v-stepper-step>
					<v-stepper-content step="5">
						<v-card :elevation="0">
							<v-form ref="step5" v-model="step6form">
								<business-information :disabled="true" />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 6" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-step step="6" :complete="e6 > 6" @click="e6 = 6" :rules="step6">
						Branch(s)
					</v-stepper-step>
					<v-stepper-content step="6">
						<v-card class="mb-12" :elevation="0">
							<v-checkbox label="Single Location" disabled color="indigo" v-model="otherPrimaryDetails.single_location"
								hide-details="auto" class="mb-5"></v-checkbox>

							<view-uploaded-branches :disabled="true" @newValue='countBranchPage' />

							<div class="text-center mb-5" v-if="existingUploadedBranches.length >= 1">
								<v-pagination @input="changePage" v-model="page" :total-visible="5"
									:length="branchPage.length"></v-pagination>
							</div>

						</v-card>
						<v-divider></v-divider>
						<v-row class="mt-2">
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 7" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<!-- @click="status === 'Initial Evaluation Complete' || status === 'Onboarding Approval Pending' ? e6 = 7 : ''" -->
					<v-stepper-step step="7" :complete="e6 > 7" @click="e6 = 7" :rules="step7">
						Ownership
					</v-stepper-step>
					<v-stepper-content step="7">
						<v-card class="mb-12" :elevation="0">
							<view-uploaded-owners :disabled="true" />
							<!-- <v-divider></v-divider>
							<v-form ref="ownerToUpload" v-model="ownerToUpload">
								<owners :disabled="false" />
							</v-form> -->
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 9" color="info" style="float:right;" v-if="hasBod == false">
									<b>Continue</b>
								</v-btn>
								<v-btn v-else @click="e6 = 8" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>
					<v-stepper-step step="8" :complete="e6 > 8" @click="e6 = 8" :rules="step7" v-if="hasBod == true">
						Board of Directors
					</v-stepper-step>
					<v-stepper-content step="8" v-if="hasBod == true">
						<v-card class="mb-12" :elevation="0">
							<ViewUploadedBod :disabled="true" />
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>
								<v-btn @click="e6 = 9" color="info" style="float:right;">
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>
					<v-stepper-step step="9" :complete="e6 > 9" @click="e6 = 9">
						BSP License
					</v-stepper-step>
					<v-stepper-content step="9">
						<v-card class="mb-12" :elevation="0">
							<ViewExistingBSP />

							<br>
							<v-textarea v-if="currUser.user_role !== 'encoder' && !$route.query.viewOnly" label="Comment"
								hide-details="auto" outlined dense @change="change"></v-textarea>

						</v-card>
						<v-divider class="my-5"></v-divider>

						<v-row v-if="!$route.query.viewOnly">
							<v-col>
								<v-btn color="primary" @click="e6 -= 1" outlined>
									<b>Back</b>
								</v-btn>

								<v-btn v-if="this.currUser.is_onboarding_committee === '1'" class="mr-4" color="primary"
									style="float:right;" @click="approve">
									<b>Approve SP</b>
								</v-btn>
								<v-btn v-else-if="this.currUser.is_validation_committee === '1'" class="mr-4" color="primary"
									style="float:right;" @click="approveValidation">
									<b>Approve SP</b>
								</v-btn>


								<v-btn v-if="this.currUser.is_onboarding_committee === '1' && final_comment" class="mr-4"
									color="primary" outlined style="float:right;" @click="returnOnboarding">
									<b>Return SP</b>
								</v-btn>
								<v-btn v-else-if="this.currUser.is_validation_committee === '1' && final_comment" class="mr-4"
									color="primary" outlined style="float:right;" @click="returnValidation">
									<b>Return SP</b>
								</v-btn>
								<v-btn v-else-if="this.currUser.is_onboarding_committee === '1'" class="mr-4" disabled color="primary"
									outlined style="float:right;">
									<b>Return SP</b>
								</v-btn>
								<v-btn v-else-if="this.currUser.is_validation_committee === '1'" class="mr-4" disabled color="primary"
									outlined style="float:right;">
									<b>Return SP</b>
								</v-btn>

							</v-col>
						</v-row>
					</v-stepper-content>
				</v-stepper>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileCard from '../components/modules/salesProspecting/ProfileCard.vue';
import SoleProprietor from '../components/SoleProprietor.vue';
import CooperativeHybrid from '../components/modules/salesProspecting/CooperativeHybrid.vue';
import Corporation from '../components/modules/salesProspecting/Corporation.vue';
// import Branches from '../components/modules/salesProspecting/Branches.vue';
// import Owners from '../components/modules/salesProspecting/Owners.vue';
import PrimaryBusinessAddress from '../components/modules/salesProspecting/PrimaryBusinessAddress.vue';
import PrimaryContactInformation from '../components/modules/salesProspecting/PrimaryContactInformation.vue';
import ComplianceOfficer from '../components/modules/salesProspecting/ComplianceOfficer.vue';
import BusinessInformation from '../components/modules/salesProspecting/BusinessInformation.vue';
import ViewUploadedBod from '../components/modules/salesProspecting/ViewUploadedBod.vue'
import ViewUploadedOwners from '../components/modules/salesProspecting/ViewUploadedOwners.vue';
import ViewUploadedBranches from '../components/modules/salesProspecting/ViewUploadedBranches.vue'
import ViewExistingBSP from '../components/modules/salesProspecting/ViewExistingBSP.vue'
import jsonToFormData from 'json-form-data';
import _ from 'lodash';

export default {
	components: {
		SoleProprietor: SoleProprietor,
		CooperativeHybrid: CooperativeHybrid,
		// Branches: Branches,
		// Owners: Owners,
		Corporation: Corporation,
		ProfileCard: ProfileCard,
		PrimaryBusinessAddress: PrimaryBusinessAddress,
		PrimaryContactInformation: PrimaryContactInformation,
		ComplianceOfficer: ComplianceOfficer,
		BusinessInformation: BusinessInformation,
		ViewUploadedOwners: ViewUploadedOwners,
		ViewUploadedBranches: ViewUploadedBranches,
		ViewExistingBSP: ViewExistingBSP,
		ViewUploadedBod: ViewUploadedBod
	},
	async created() {
		const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
		// await this.$store.dispatch('salesProspecting/doGetZip', this.hrisToken)

		const payload = {
			mother_entity_id: resSP.data.result[0].mother_entity_id,
			is_mother_entity: resSP.data.result[0].is_mother_entity,
			sub_entities: resSP.data.result[0].sub_entities
		}

		this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS', (payload))

		this.countBranchPage(resSP.data.result[0].sp_branch)

		this.assignData(resSP.data.result[0])
		this.decodeResUpload(resSP.data.result[0].sp_location)
		this.decodeOwners(resSP.data.result[0].sp_owner)
		this.decodeBranches(resSP.data.result[0].sp_branch.data)
		this.decodeBod(resSP.data.result[0].sp_bod)
		this.decodeComplianceUploads(resSP.data.result[0].sp_compliance_officer_delegate)
		this.decodePrimaryContactInformationUploads(resSP.data.result[0].sp_primary_contact_information)
		// await this.$store.dispatch('salesProspecting/doGetCountries')
		// await this.$store.dispatch('salesProspecting/doGetCities')

	},

	data: () => ({
		props: ['id'],
		status: '',
		e6: 1,
		businessType: '',
		step1: [],
		step2: [],
		step3: [],
		step4: [],
		step5: [],
		step6: [],
		step7: [],
		step2form: true,
		step3form: true,
		step4form: true,
		step5form: true,
		step6form: true,
		uploadedBranches: true,
		branchToUpload: true,
		ownerToUpload: true,
		steps: ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7'],
		hasBod: true,
		page: 1,
		branchPage: []
	}),
	computed: {
		...mapGetters({
			// Added sales-prospecting getters to map the variable Sales Prospecting details
			salesProspectingForm: 'salesProspecting/salesProspectingForm',
			documents: 'salesProspecting/documents',
			cities: 'salesProspecting/cities',
			countries: 'salesProspecting/countries',
			owners: 'salesProspecting/owners',
			branches: 'salesProspecting/branches',
			primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
			primaryContactInfo: 'salesProspecting/primaryContactInfo',
			complianceOfficer: 'salesProspecting/complianceOfficer',
			otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails',
			existingUploadedFiles: 'salesProspecting/existingUploadedFiles',
			existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
			existingUploadedOwners: 'salesProspecting/existingUploadedOwners',
			final_comment: 'salesProspecting/final_comment',
			currUser: 'auth/currUser',
			zip: 'salesProspecting/zip',
			hrisToken: 'auth/hrisToken',
		}),
	},
	methods: {
		countBranchPage(e) {
			this.page = 1
			this.branchPage = []
			if (e) {
				let number = e.last_page
				for (let i = 1; i <= number; i++) {
					this.branchPage.push(i)
				}
			}
		},
		async changePage(e) {
			const payload = {
				id: this.$route.params.id,
				page: e
			}
			const res = await this.$store.dispatch('salesProspecting/changePageBranch', { payload })
			if (res.status === 200) {
				this.decodeBranches(res.data.result[0].sp_branch.data)
			}
		},
		async returnValidation() {
			const initialPayload = { comment: this.final_comment }
			const payload = { id: this.$route.params.id, payload: initialPayload }

			console.log(payload)
			const res = await this.$store.dispatch('salesProspecting/doReturnValidation', { payload })
			console.log('Validation Committee')
			if (res.status === 200) {
				this.$router.push('/sales-prospecting')
			}
		},
		async returnOnboarding() {
			const initialPayload = { comment: this.final_comment }
			const payload = { id: this.$route.params.id, payload: initialPayload }

			console.log(payload)
			const res = await this.$store.dispatch('salesProspecting/doReturnOnboarding', { payload })
			console.log('Onboarding')
			if (res.status === 200) {
				this.$router.push('/sales-prospecting')
			}
		},
		change(payload) {
			console.log(payload)
			this.$store.commit('salesProspecting/SET_FINAL_COMMENT', payload)
		},
		returnSP() {
			console.log('Hello')
		},
		async approve () {
			const initialPayload = { comment: this.final_comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}
			const res = await this.$store.dispatch('salesProspecting/doApproveSPByOnboarding', {payload})
			console.log(res)
			if (res.status === 200) {
				this.$router.push('/sales-prospecting')
			}
		},
		async approveValidation(){
			const initialPayload = { comment: this.final_comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}
			const res = await this.$store.dispatch('salesProspecting/doApproveSPByValidation', {payload})
			console.log(res)
			if (res.status === 200) {
				this.$router.push('/existing-sales-prospecting')
			}
		},
		assignData(data) {
			// Added assign data for sales-prospecting to get the following details based on the Selected SP and pass it again to the components
			var salesProspectingForm = {
				id: '',
				account_name: data.sp_primary_details.account_name,
				legal_entity_name: data.sp_primary_details.legal_entity_name,
				trade_name: data.sp_primary_details.trade_name,
				bp_idno: data.bp_idno,
				scorecard: JSON.parse(data.sp_primary_details.scorecard),
				date_presentation: data.sp_primary_details.date_presentation,
				documents_presented: data.sp_primary_details.documents_presented === null ? [] : JSON.parse(data.sp_primary_details.documents_presented),
				summary_presentation: data.sp_primary_details.summary_presentation,
				accept_or_negotiate: data.sp_primary_details.accept_or_negotiate,
				scorecard_result: data.sp_primary_details.scorecard_result,
				uploaded_loi: data.sp_primary_details.upload_loi === null ? [] : data.sp_primary_details.upload_loi,
				uploaded_picture: data.sp_primary_details.upload_picture === null ? [] : data.sp_primary_details.upload_picture,
				uploaded_supp_docs: data.sp_primary_details.upload_supp_docs === null ? [] : data.sp_primary_details.upload_supp_docs
			}
			this.$store.commit('salesProspecting/SET_SELECTED_SP', salesProspectingForm)

			if (data.sp_location[0]) {
				if (data.sp_location[0].type == 'Single Proprietor') {
					this.hasBod = false
				}
			} else if (!data.sp_location[0]) {
				this.hasBod = true
			}

			this.status = data.status
			var details = {}
			details.created_at = data.created_at
			details.profile = data.profile
			details.status = data.status
			details.account_name = data.sp_primary_details.account_name
			this.$store.commit('salesProspecting/SET_PRIMARY_DETAILS', details)
			if (data.sp_primary_business_address) {
				this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS', data.sp_primary_business_address)
			}
			if (data.sp_primary_contact_information) {
				this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO', data.sp_primary_contact_information)
			}
			if (data.sp_compliance_officer_delegate) {
				this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER', data.sp_compliance_officer_delegate)
			}
			if (data.sp_other_primary_details) {
				data.sp_other_primary_details.board_of_directors = data.sp_other_primary_details.board_of_directors ? JSON.parse(data.sp_other_primary_details.board_of_directors) : this.otherPrimaryDetails.board_of_directors
				data.sp_other_primary_details.list_of_non_ex_remco = JSON.parse(data.sp_other_primary_details.list_of_non_ex_remco)
				data.sp_other_primary_details.list_of_avp_products = JSON.parse(data.sp_other_primary_details.list_of_avp_products)
				data.sp_other_primary_details.business_information = JSON.parse(data.sp_other_primary_details.business_information)
				data.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(data.sp_other_primary_details.business_type_of_sub_agents)
				data.sp_other_primary_details.top_reasons_for_WU = JSON.parse(data.sp_other_primary_details.top_reasons_for_WU)
				data.sp_other_primary_details.typical_customers = JSON.parse(data.sp_other_primary_details.typical_customers)
				// this.$store.commit('salesProspecting/SET_BRANCHES', JSON.parse(data.sp_other_primary_details.branch));
				// this.$store.commit('salesProspecting/SET_OWNERS', JSON.parse(data.sp_other_primary_details.ownership));
				this.businessType = data.sp_other_primary_details.type
				this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS', data.sp_other_primary_details)
			}
		},
		decodeComplianceUploads (resUpload) {
			if (resUpload.amla_certificate !== null) {
				resUpload.amla_certificate = JSON.parse(resUpload.amla_certificate)
			}
			if (resUpload.valid_id !== null) {
				resUpload.valid_id = JSON.parse(resUpload.valid_id)
			}
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_COMPLIANCE', resUpload)
		},
		decodePrimaryContactInformationUploads (resUpload) {
			if (resUpload.amla_certificate !== null) {
				resUpload.amla_certificate = JSON.parse(resUpload.amla_certificate)
			}
			if (resUpload.valid_id !== null) {
				resUpload.valid_id = JSON.parse(resUpload.valid_id)
			}
			this.$store.commit('salesProspecting/SET_EXISTING_PRIMARY_CONTACT_INFORMATION', resUpload)
		},
		decodeResUpload (resUpload) {
			if (resUpload.length > 0) {

				if (resUpload[0].is_western_union == 1) {
					this.$store.commit('salesProspecting/SET_WESTERN_MOA', true)
				}
				if (resUpload[0].is_avp == 1) {
					this.$store.commit('salesProspecting/SET_AVP_MOA', true)
				}
				if (resUpload[0].is_nonex == 1) {
					this.$store.commit('salesProspecting/SET_NONEX_MOA', true)
				}

				var type = resUpload[0].type
				this.businessType = type
				if (type === 'Single Proprietor') {
					resUpload[0].dti = JSON.parse(resUpload[0].dti)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				} else if (type === 'Cooperative/Hybrid') {
					resUpload[0].cda = JSON.parse(resUpload[0].cda)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				} else if (type === 'Corporation') {
					resUpload[0].sec = JSON.parse(resUpload[0].sec)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].gis = JSON.parse(resUpload[0].gis)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				}
				this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_FILES', resUpload[0])
			}
		},
		uploadOwners() {
			this.$store.commit('salesProspecting/SET_RULE_GENERIC')
			this.$store.commit('salesProspecting/SET_RULE_FILE')
			this.$store.commit('salesProspecting/SET_RULE_EMAIL')
			setTimeout(() => {
				if (this.$refs.ownerToUpload.validate()) {
					var initialPayload = this.owners
					this.callUploadOwners(initialPayload)
				} else {
					this.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'There are fields that have not been filled out!', 'messagetype': 'error' }, { root: true });
				}

			})
		},
		async callUploadOwners(initialPayload) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function (value) {
					if (typeof value === 'boolean') {
						return +value ? '1' : '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = { id: this.$route.params.id, payload: convertedFormData }
			await this.$store.dispatch('salesProspecting/doUploadOwner', { payload })
			this.reloadOwners()
			this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
			this.$store.commit('salesProspecting/UNSET_RULE_FILE')
			this.$store.commit('salesProspecting/UNSET_RULE_EMAIL')
		},
		uploadBranches() {
			this.$store.commit('salesProspecting/SET_RULE_GENERIC')
			this.$store.commit('salesProspecting/SET_RULE_FILE')
			this.$store.commit('salesProspecting/SET_RULE_EMAIL')
			setTimeout(() => {
				var errDays = []
				this.branches.operating_hours.forEach(function (e) {
					if (!e.isClosed && (e.timeStart === '' || e.timeEnd === '')) {
						errDays.push(e)
					}
				})
				if (errDays.length == 0) {
					if (this.$refs.branchToUpload.validate()) {
						var initialPayload = this.branches
						this.callUploadBranches(initialPayload)
					} else {
						this.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'There are fields that have not been filled out!', 'messagetype': 'error' }, { root: true });
					}
				} else {
					this.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'There are Operation Hours that have not been filled out!', 'messagetype': 'error' }, { root: true });
					console.log(errDays)
				}
			})
		},
		async callUploadBranches(initialPayload) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function (value) {
					if (typeof value === 'boolean') {
						return +value ? '1' : '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = { id: this.$route.params.id, payload: convertedFormData }
			await this.$store.dispatch('salesProspecting/doUploadBranch', { payload })
			this.reloadBranches()
			this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
			this.$store.commit('salesProspecting/UNSET_RULE_FILE')
			this.$store.commit('salesProspecting/UNSET_RULE_EMAIL')
		},
		async uploadDocuments() {
			var initialPayload = ''
			if (this.businessType === 'Single Proprietor') {
				initialPayload = this.documents['single_proprietor']
			} else if (this.businessType === 'Cooperative/Hybrid') {
				initialPayload = this.documents['cooperative_hybrid']
			} else if (this.businessType === 'Corporation') {
				initialPayload = this.documents['corporation']
			}
			initialPayload.type = this.businessType
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function (value) {
					if (typeof value === 'boolean') {
						return +value ? '1' : '0';
					}
					return value;
				}
			};
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = { id: this.$route.params.id, payload: convertedFormData }
			await this.$store.dispatch('salesProspecting/doUploadFiles', { payload })
			this.reloadUploads()
		},
		decodeOwners(resOwners) {
			let expiryFile = {
				file: null,
				date_issued: '',
				date_expiry: '',
				menuIssued: false,
				menuExpiry: false
			}
			let nonExpiryFile = {
				file: null
			}
			resOwners.forEach(function (e) {
				e.owner_id = e.id
				e.amlaUploaded = JSON.parse(e.amla)
				e.court_clearanceUploaded = JSON.parse(e.court_clearance)
				e.nbiUploaded = JSON.parse(e.nbi)
				e.picture_2x2Uploaded = JSON.parse(e.picture_2x2)
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(expiryFile)
				e.court_clearance = _.clone(expiryFile)
				e.nbi = _.clone(expiryFile)
				e.amla = _.clone(expiryFile)
				e.picture_2x2 = _.clone(nonExpiryFile)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_OWNERS', resOwners)
		},
		decodeBranches(resBranches) {
			console.log(resBranches)
			let expiryFile = {
				file: null,
				date_issued: '',
				date_expiry: '',
				menuIssued: false,
				menuExpiry: false
			}
			resBranches.forEach(function (e) {
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.coordinates = JSON.parse(e.coordinates)
				e.business_permit = _.clone(expiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
					// f.valid_idUploaded = JSON.parse(f.valid_id)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(expiryFile)
					// f.valid_id = _.clone(expiryFile)
				})
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
			this.existingUploadedBranches.map(coords => {
				coords.coordinates.lat = parseFloat(coords.coordinates.lat)
				coords.coordinates.lng = parseFloat(coords.coordinates.lng)
			})
		},
		decodeBod(resBod) {
			let birthdate = {
				menuIssued: false,
				menuExpiry: false
			}
			// let expiryFile = {
            //     file: null,
            //     date_issued: '',
            //     date_expiry: '',
            //     menuIssued: false,
            //     menuExpiry: false
            // }
            let nonExpiryFile = {
                file: null
            }
			resBod.forEach(function(e) {
				e.bod_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiryFile)
				e.amlaUploaded = JSON.parse(e.amla_certificate)
				e.amla_certificate = _.clone(nonExpiryFile)
				e.nbiUploaded = JSON.parse(e.nbi_clearance)
				e.nbi_clearance = _.clone(nonExpiryFile)
				e.menu = _.clone(birthdate)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_BOD', resBod)
		},
		async reloadBranches() {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeBranches(res.data.result[0].sp_branch.data)
			this.countBranchPage(res.data.result[0].sp_branch)
			this.$store.commit('salesProspecting/SET_BRANCHES_DEFAULT')
		},
		async reloadOwners() {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeOwners(res.data.result[0].sp_owner)
			this.$store.commit('salesProspecting/SET_OWNERS_DEFAULT')
		},
		async reloadUploads() {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeResUpload(res.data.result[0].sp_location)
			this.$store.commit('salesProspecting/SET_DOCUMENTS_DEFAULT')
		},
		save(isDraft) {
			const initialPayload = {
				is_draft: isDraft,
				type: this.businessType,
				primary_business_address: this.primaryBusinessAddress,
				primary_contact_information: this.primaryContactInfo,
				compliance_officer_delegate: this.complianceOfficer,
				other_details: this.otherPrimaryDetails
			}
			const payload = { id: this.$route.params.id, payload: initialPayload }
			if (isDraft) {
				this.callSave(payload)
			} else {
				const self = this
				self.$store.commit('salesProspecting/SET_RULE_GENERIC')
				self.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
				self.$store.commit('salesProspecting/SET_RULE_EMAIL')
				setTimeout(() => {
					var step1Validated = false
					var step6Validated = false
					var step7Validated = false
					self.steps.forEach(e => {
						if (e === 'step1') {
							if (self.existingUploadedFiles.length === 0) {
								self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'No files uploaded!', 'messagetype': 'error' }, { root: true });
								self[e] = [() => false]
							} else {
								if (self.businessType === '') {
									self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'There are some fields that have not been filled out!', 'messagetype': 'error' }, { root: true });
									self[e] = [() => false]
								} else if (self.businessType === 'Single Proprietor') {
									if (self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.business_permit && self.existingUploadedFiles.dti && self.existingUploadedFiles.supplier_reference) {
										step1Validated = true
										console.log('validated single')
									} else {
										self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'File Uploads are not complete!', 'messagetype': 'error' }, { root: true });
										self[e] = [() => false]
										console.log('not validated single')
									}
								} else if (self.businessType === 'Cooperative/Hybrid') {
									console.log('coop')
									if (self.existingUploadedFiles.cda && self.existingUploadedFiles.business_permit && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.org_chart && self.existingUploadingFiles.board_resolution) {
										step1Validated = true
									} else {
										self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'File Uploads are not complete!', 'messagetype': 'error' }, { root: true });
										self[e] = [() => false]
									}
								} else if (self.businessType === 'Corporation') {
									console.log('corp')
									if (self.existingUploadedFiles.sec && self.existingUploadedFiles.business_permit && self.existingUploadedFiles.bir_cor && self.existingUploadedFiles.supplier_reference && self.existingUploadedFiles.org_chart && self.existingUploadedFiles.board_resolution) {
										step1Validated = true
									} else {
										self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'File Uploads are not complete!', 'messagetype': 'error' }, { root: true });
										self[e] = [() => false]
									}
								}
							}
						} else if (e === 'step6') {
							console.log('step6')
							if (self.existingUploadedBranches.length === 0) {
								self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'You need to add at least 1 branch!', 'messagetype': 'error' }, { root: true });
								self[e] = [() => false]
							} else {
								self[e] = []
								step6Validated = true
							}
						} else if (e === 'step7') {
							console.log('step7')
							if (self.existingUploadedOwners.length === 0) {
								self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'You need to add at least 1 owner!', 'messagetype': 'error' }, { root: true });
								self[e] = [() => false]
							} else {
								self[e] = []
								step7Validated = true
							}
						} else {
							if (!self.$refs[e].validate()) {
								self[e] = [() => false]
								self.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'There are fields that have not been filled out!', 'messagetype': 'error' }, { root: true });
							} else {
								self[e] = []
							}
						}
					})
					console.log(step1Validated, 'step 1 validated')
					if (self.$refs.step3.validate() && self.$refs.step4.validate() && step1Validated && step6Validated && step7Validated) {
						self.steps.forEach(e => {
							self[e] = []
						})
						this.callSave(payload)
					}
				})
			}
		},
		async callSave(payload) {
			const res = await this.$store.dispatch('salesProspecting/doUpdateSalesProspecting', { payload })
			if (res.status === 200) {
				this.$store.commit('salesProspecting/UNSET_RULE_GENERIC')
				this.$store.commit('salesProspecting/UNSET_RULE_CHECKBOX')
				this.$store.commit('salesProspecting/UNSET_RULE_SCORECARD')
				this.$store.commit('salesProspecting/SET_BRANCHES_DEFAULT')
				this.$store.commit('salesProspecting/SET_OWNERS_DEFAULT')
				this.$router.push('/sales-prospecting');
			}
		}
	}
};
</script>